import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Affiliation } from 'app/models/affilitian';
import { Answer } from 'app/models/answer';
import { CompletePracticeStructure } from 'app/models/completePractice';
import { Module } from 'app/models/module';
import { Question } from 'app/models/question';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { ModulesManagementService } from 'app/services/modules/modules-management.service';
import { UtilService } from 'app/services/util/util.service';
import { timingSafeEqual } from 'crypto';


@Component({
  selector: 'app-create-answer-modal',
  templateUrl: './create-answer-modal.component.html',
  styleUrls: ['./create-answer-modal.component.css']
})
export class CreateAnswerModalComponent implements OnInit {

  public modalForm: FormGroup;
  public module: Module;
  public inputData: any;
  public formType: string = "question";
  public title: string = "Crea Nuova Affiliazione";

  constructor(fb: FormBuilder, public authServ: AuthenticationService, public moduleServ: ModulesManagementService, private fireDB: FirebaseDbCollectionsService, public modalRef: NgbActiveModal, public util: UtilService) {
    this.modalForm = fb.group({
      description: new FormControl("", Validators.required),
      nextEventType: new FormControl(""),
      nextEventValue: new FormControl(""),
      serviceName: new FormControl(""),
      price: new FormControl(0),
      completeDescription: new FormControl(""),
      type: new FormControl(""),
      placeholder: new FormControl(""),
      multiple: new FormControl(false),
      enableAction: new FormControl(false),
      contactText: new FormControl("")
    })
  }

  public moduleQuestionsList: Question[] = [];

  ngOnInit() {
    if (this.module) {
      this.moduleQuestionsList = this.moduleServ.questionList.filter(x => x.moduleID == this.module.$key)
    }

    if (this.inputData) {
      if (this.formType == 'answer') {
        this.setAnswer(this.inputData);
      } else {
        this.setQuestion(this.inputData);
      }
    }
  }

  ngAfterViewInit() {

  }

  setQuestion(question: Question) {
    this.modalForm.controls.description.setValue(question.description);
  }

  setAnswer(answer: Answer) {
    this.modalForm.controls.description.setValue(answer.description);
    this.modalForm.controls.nextEventValue.setValue(answer.nextEvent.questionID);
    this.modalForm.controls.nextEventType.setValue(answer.nextEvent.type);
    this.modalForm.controls.price.setValue(answer.nextEvent.price);
    this.modalForm.controls.serviceName.setValue(answer.nextEvent.serviceName);
    this.modalForm.controls.contactText.setValue(answer.nextEvent.contactText);

    if (answer.completeAction) {
      this.modalForm.controls.enableAction.setValue(true);
      this.modalForm.controls.completeDescription.setValue(answer.completeAction.description);
      this.modalForm.controls.type.setValue(answer.completeAction.type);
      this.modalForm.controls.placeholder.setValue(answer.completeAction.placeholder);
      this.modalForm.controls.multiple.setValue(answer.completeAction.multiple);
    }
  }

  checkFormValid() {
    if (this.formType == 'answer') {
      if (this.modalForm.value.nextEventType == "question") {
        if (!this.modalForm.value.nextEventValue) {
          this.util.presentErrorToast("Non hai selezionato la domanda successiva.")
          return false;
        }
      } else if (this.modalForm.value.nextEventType == "goToPay") {
        if (!this.modalForm.value.serviceName) {
          this.util.presentErrorToast("Non hai inserito il nome del servizio.")
          return false;
        }
        if (!this.modalForm.value.price || this.modalForm.value.price <= 0) {
          this.util.presentErrorToast("Prezzo non valido.")
          return false;
        } else if (typeof this.modalForm.value.price == "number") {
          // FORMATTO IL PREZZO 
          let price = this.modalForm.value.price.toFixed(2);
          this.modalForm.controls.price.setValue(price);
        }
      }

      if (this.modalForm.value.enableAction) {
        if (!this.modalForm.value.completeDescription ||
          !this.modalForm.value.type ||
          !this.modalForm.value.placeholder) {
          this.util.presentErrorToast("Non hai compilato tutti i campi dell'azione di completamento.");
          return false;
        }
      }
    }

    return true;
  }

  save() {
    if (this.modalForm.valid && this.checkFormValid()) {
      if (this.modalForm.value.nextEventType != "question") {
        this.modalForm.value.nextEventValue = '';
      }

      this.modalRef.close(this.modalForm.value);

    } else {
      this.modalForm.markAllAsTouched();
      // this.modalForm.get('code').hasError('required') && this.modalForm.get('code').touched;
      this.util.presentWarningToast("Compilare i campi obbligatori");
    }
  }
}
