import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Affiliation } from 'app/models/affilitian';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { UtilService } from 'app/services/util/util.service';

@Component({
  selector: 'app-create-affiliation-modal',
  templateUrl: './create-affiliation-modal.component.html',
  styleUrls: ['./create-affiliation-modal.component.css']
})
export class CreateAffiliationModalComponent implements OnInit {

  public affiliationForm: FormGroup;
  public affiliation: Affiliation;
  public title: string = "Crea Nuova Affiliazione";

  constructor(fb: FormBuilder, public authServ: AuthenticationService, private fireDB: FirebaseDbCollectionsService, public modalRef: NgbActiveModal, public util: UtilService) {
    this.affiliationForm = fb.group({
      discount: new FormControl(0, Validators.required),
      code: new FormControl("", Validators.required),
      reference: new FormControl("", Validators.required),
      state: new FormControl("", Validators.required),
      userUID: new FormControl(""),
      date: new FormControl(new Date()),
      $key: new FormControl(""),
    })
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.affiliation) {
      this.affiliationForm.controls.discount.setValue(this.affiliation.discount ? this.affiliation.discount : 0);
      this.affiliationForm.controls.code.setValue(this.affiliation.code ? this.affiliation.code : "");
      this.affiliationForm.controls.reference.setValue(this.affiliation.reference ? this.affiliation.reference : "");
      this.affiliationForm.controls.state.setValue(this.affiliation.state ? this.affiliation.state : "");
      this.affiliationForm.controls.userUID.setValue(this.affiliation.userUID ? this.affiliation.userUID : "");
      this.affiliationForm.controls.date.setValue(this.affiliation.date ? this.affiliation.date : "");
      this.affiliationForm.controls.$key.setValue(this.affiliation.$key ? this.affiliation.$key : "");
    }
  }

  updateaffiliationInfo() {
    if (this.affiliationForm.valid) {
      if (this.affiliation && this.affiliation.date) {
        this.affiliationForm.value.date = new Date(this.affiliation.date);
        this.affiliationForm.controls.date.setValue(this.affiliationForm.value.date.getTime());

        this.fireDB.firestoreUpdate('affiliations', this.affiliationForm.value).then(res => {
          console.log("affiliation saved on realtime db")
          this.util.presentSuccessToast("Affiliazione salvata con successo");
          this.modalRef.dismiss();
        });
      } else {
        this.affiliationForm.controls.date.setValue(this.affiliationForm.value.date.getTime());

        this.fireDB.firestoreCreate('affiliations', this.affiliationForm.value).then(res => {
          console.log("affiliation saved on realtime db")
          this.util.presentSuccessToast("Affiliazione salvata con successo");
          this.modalRef.dismiss();
        });
      }


    } else {
      this.affiliationForm.markAllAsTouched();
      // this.affiliationForm.get('code').hasError('required') && this.affiliationForm.get('code').touched;
      this.util.presentWarningToast("Compilare i campi obbligatori");
    }
  }

}
