import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UtilService } from 'app/services/util/util.service';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  username: string = '';
  password: string = '';

  // alerts: iAlertMessage[] = new Array<AlertMessage>();
  public completationError: boolean;
  logoUrl = 'assets/img/logos/logo-proger.png';
  public loginForm: FormGroup;

  constructor(public router: Router, private _modalService: NgbModal, fb: FormBuilder, private authService: AuthenticationService, private util: UtilService) {
    this.loginForm = fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    })
  }

  ngOnInit(): void {
    if (window.location.host == 'localhost:4200') {
      this.loginForm.controls.username.setValue("g.auriemma");
      this.loginForm.controls.password.setValue("bomamjoir");
    }
  }

  onLoggedin(value) {

  }
  openModal(title: string, message: string, showUndo: boolean) {
    // const modalRef = this._modalService.open(MODALS.autofocus);
    // modalRef.componentInstance.showUndoButton = showUndo;
    // modalRef.componentInstance.title = title;
    // modalRef.componentInstance.message = message;
  }

  setCustomerUser() {

  }

  recoverPassword() {

    // if (!this.loginForm.value.username) {
    //     this.toaster.error("E' necessario specificare l'username per avviare il recupero password", '');
    // } else {
    //     this.service.RecoverUserPassword({ "username": this.loginForm.value.username }).subscribe(res =>{
    //         if(res.value && res.value.message == 'ok'){
    //             this.toaster.success("Recupero Password Avviato", 'Controlla la tua casella di posta elettronica per eseguire la procedura di recupero password.');
    //             this.router.navigate(["recoverPassword"]);
    //         }else{
    //             this.toaster.error("Attenzione", res.value.message);
    //         }
    //     });
    // }
    //#586647 creata una pagina a parte per il recupero password
    this.router.navigate(["/recoverPasswordByUser"]);
  }

  logIn(value) {
    this.authService.SignIn(value.username, value.password)
      .then((res) => {
        console.log(res.user.uid)
        this.authService.setUserData(res.user.uid, true);

        // if (!this.authService.userInfo.isAdmin) {
        //   this.util.presentErrorToast("Il tuo indirizzo email non è stato ancora verificato");
        //   // window.alert('Email non valida')
        // }
        // if (!this.authService.isEmailVerified) {
        //   this.util.presentErrorToast("Il tuo indirizzo email non è stato ancora verificato");
        //   // window.alert('Email non valida')
        // }
        // this.authService.getUserInfo(res.user);
        // this.router.navigate(['practiceManager']);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  googleAuth() {
    this.authService.GoogleAuth();
  }

}
