import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { Observable } from "rxjs";

@Injectable()
export class CanActivateRoute implements CanActivate {
    constructor(private authServ: AuthenticationService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // return this.permissions.canActivate(this.currentUser, route.params.id);
        const canNavigate = this.authServ.isLoggedIn;
        if (!canNavigate) {
            this.router.navigate(["login"]);
        }
        return this.authServ.isLoggedIn;
    }
}