import { Injectable } from '@angular/core';
import { Answer } from 'app/models/answer';
import { Module } from 'app/models/module';
import { Question } from 'app/models/question';
import { FirebaseDbCollectionsService } from '../firebase-db-collections/firebase-db-collections.service';
import { getDatabase, ref, set } from "firebase/database";

@Injectable({
  providedIn: 'root'
})
export class ModulesManagementService {

  constructor(public fireDB: FirebaseDbCollectionsService) {
    this.getModules();
    this.getQuestions();
    this.getAnswer();
  }

  public moduleList: Module[] = [];
  public questionList: Question[] = [];
  public answerList: Answer[] = [];

  async getModules() {
    let res = this.fireDB.getList('modules');
    res.snapshotChanges().subscribe(res => {
      this.moduleList = [];
      res.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.moduleList.push(a as Module);
      })
      console.log(this.moduleList)
    })
  }

  async getQuestions() {
    let res = this.fireDB.getList('questions');
    res.snapshotChanges().subscribe(res => {
      this.questionList = [];
      res.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.questionList.push(a as Question);
      })
      console.log(this.questionList)
    })
  }

  async getAnswer() {
    let res = this.fireDB.getList('answer');
    res.snapshotChanges().subscribe(res => {
      this.answerList = [];
      res.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.answerList.push(a as Answer);
      })
      console.log(this.answerList)
    })
  }

  setQuestion(question: Question) {
    if (question.$key) {
      return this.fireDB.updatObject("questions", question.$key, question);
    } else {
      return this.fireDB.create('questions', question);
    }
  }

  setAnswer(answer: Answer) {
    if (answer.$key) {
      return this.fireDB.updatObject("answer", answer.$key, answer);
    } else {
      return this.fireDB.create('answer', answer);
    }
  }

}
