import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { collection, addDoc, getDocs, setDoc, doc, Firestore, getFirestore, updateDoc, query, deleteDoc } from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class FirebaseDbCollectionsService {
  ListRef: AngularFireList<any>;
  Ref: AngularFireObject<any>;

  firestoreDB: Firestore;

  constructor(private db: AngularFireDatabase) {
    this.firestoreDB = getFirestore();
  }

  // Create
  create(ref: string, elem: any) {
    this.getList(ref);
    return this.ListRef.push(elem);
  }

  // Get Single
  getObject(ref: string, id: string) {
    this.Ref = this.db.object('/' + ref + '/' + id);
    return this.Ref;
  }

  // Get List
  getList(ref: string,) {
    this.ListRef = this.db.list('/' + ref);
    return this.ListRef;
  }

  // Update
  updatObject(ref: string, id, elem) {
    this.getObject(ref, id);
    delete elem['$key'];
    return this.Ref.update(elem);
  }

  // Delete
  deleteObject(ref: string, id: string) {
    this.Ref = this.db.object('/' + ref + '/' + id);
    return this.Ref.remove();
  }


  async firestoreSelect(ref: string) {
    // const q = query(collection(this.firestoreDB, ref), where("CodiceCliente", "==", environment.customerUID));
    const q = query(collection(this.firestoreDB, ref));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });
  }

  async firestoreCreate(ref: string, data) {
    let ID = new Date().getTime();
    data.$key = ID;
    return await setDoc(doc(this.firestoreDB, ref, ID.toString()), data).then(res => {
      return data;
    });
  }

  async firestoreUpdate(ref: string, data) {
    // const docRef = doc(this.firestoreDB, ref, data.$key);
    return await setDoc(doc(this.firestoreDB, ref, data.$key.toString()), data).then(res => {
      // return await setDoc(docRef, data).then(res => {
      return data;
    })
    ;
  }
  async firestoreDelete(ref: string, id) {
    // const docRef = doc(this.firestoreDB, ref, data.$key);
    return await deleteDoc(doc(this.firestoreDB, ref, id)).then(res => {
      // return await setDoc(docRef, data).then(res => {
      return res;
    });
  }

}
