import { Component, OnInit } from '@angular/core';
import { UtilService } from 'app/services/util/util.service';
import { doc, Firestore, getDoc, query, where } from '@angular/fire/firestore';
import { collection, getDocs } from '@firebase/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { TouchSequence } from 'selenium-webdriver';
import { Practices } from 'app/models/practices';
import { User } from 'app/models/user';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { Question } from 'app/models/question';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAnagraphicInfoComponent } from 'app/shared/user-anagraphic-info/user-anagraphic-info.component';
import { CompletePracticeStructure } from 'app/models/completePractice';

@Component({
  selector: 'app-practice-page',
  templateUrl: './practice-page.component.html',
  styleUrls: ['./practice-page.component.css']
})
export class PracticePageComponent implements OnInit {

  constructor(public util: UtilService, private fireDB: FirebaseDbCollectionsService, private modalServ: NgbModal, private firestore: Firestore, private route: ActivatedRoute, private router: Router) { }

  public state: string = "";
  public practiceID: string = "";
  public practice: Practices;
  public questionsList: Question[] = [];
  public defaultAnswerList: CompletePracticeStructure[] = [];

  ngOnInit(): void {

    this.practiceID = this.route.snapshot.paramMap.get('practiceID');

    if (this.practiceID) {
      this.getPracticeByID();
    } else {
      this.router.navigate(["practiceManager"]);
    }

  }

  async getPracticeByID() {
    const docRef = doc(this.firestore, "practices", this.practiceID);
    this.practice = (await getDoc(docRef)).data() as Practices;
    this.practice.$key = this.practiceID;
    if (!this.practice.parsedStructure) {
      this.practice.parsedStructure = [];
    }
    if (this.practice.parsedStructure.length == 0 && this.practice.structure) {
      this.practice.parsedStructure = JSON.parse(this.practice.structure);
    }
    this.practice.parsedModule = JSON.parse(this.practice.module);
    this.practice.parsedQuestionList = JSON.parse(this.practice.questionList);

    this.defaultAnswerList = this.practice.parsedStructure.filter(x => x.questionID == "module_default")
    this.getUserByUID(this.practice)
    console.log(this.practice);
    this.getModuleQuestions();

    let state = this.util.getPracticeState(this.practice);
    this.state = state == 'Da saldare' ? "Aperta" : state;
    // this.checkCompletePractice();
  }

  async getUserByUID(practice: Practices) {
    const q = query(collection(this.firestore, "users"), where("uid", "==", practice.userID));
    // const q = query(collection(this.firestore, "endpoints"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      practice.user = doc.data() as User;
    });
  }

  getModuleQuestions() {
    let questions = this.fireDB.getList('questions');

    questions.snapshotChanges().subscribe(res => {
      this.questionsList = [];
      res.forEach(item => {
        let a = item.payload.toJSON();

        let anwser = this.practice.parsedQuestionList.find(x => x["questionID"] == item.key);
        if (a["moduleID"] == this.practice.parsedModule.$key && anwser) {
          a['$key'] = item.key;
          this.questionsList.push(a as Question);
        }
      });
    });
  }

  getAnwser(question) {
    let anwser = this.practice.parsedQuestionList.find(x => x["questionID"] == question.$key);
    return anwser ? anwser.description : "";
  }

  getQuestionAnwserStructure(question) {
    let anwser = this.practice.parsedStructure.find(x => x["questionID"] == question.$key);
    return anwser ? [anwser] : [];
  }

  setPracticeWorkInProgress() {
    this.practice.inProgress = true;
    this.practice.open = true;
    this.practice.complete = false;
    this.fireDB.firestoreUpdate("practices", this.practice);
    this.util.presentSuccessToast("Pratica posta in stato 'In Lavorazione'");
  }

  setPracticeComplete() {
    this.practice.inProgress = false;
    this.practice.open = false;
    this.practice.complete = true;
    this.fireDB.firestoreUpdate("practices", this.practice);
    this.util.presentSuccessToast("Pratica posta in stato 'Evasa'");
  }

  setPracticeOpen() {
    this.practice.inProgress = false;
    this.practice.open = true;
    this.practice.complete = false;
    this.fireDB.firestoreUpdate("practices", this.practice);
    this.util.presentSuccessToast("Pratica posta in stato 'Aperta'");
  }


  openModal() {
    const modalRef = this.modalServ.open(UserAnagraphicInfoComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.user = this.practice.user;
    modalRef.componentInstance.userForm.controls.$key.setValue(this.practice.userID);
    // modalRef.componentInstance.title = title;
    // modalRef.componentInstance.message = message;

  }

  onStateChange() {
    switch (this.state) {
      case "Aperta":
        this.setPracticeOpen()
        break;
      case "In lavorazione":
        this.setPracticeWorkInProgress()
        break;
      case "Chiusa":
        this.setPracticeComplete()
        break;

      default:
        break;
    }
  }
}
