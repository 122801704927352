import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { User } from 'app/models/user';
import firebase from 'firebase/compat/app';
import { FirebaseDbCollectionsService } from '../firebase-db-collections/firebase-db-collections.service';
import { Firestore, query, where } from '@angular/fire/firestore';
import { collection, getDocs } from '@firebase/firestore';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userData: any;
  userInfo: User;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private fireDB: FirebaseDbCollectionsService,
    private firestore: Firestore,
    private util: UtilService
  ) {
    this.ngFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this.setUserData(user.uid);
        // this.getUserInfo(this.userData.multiFactor.user);
        // this.createUser();
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        JSON.stringify(console.log(this.userData))
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password)
  }

  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password)
  }

  // Email verification when new user register
  // SendVerificationMail() {
  //   return this.ngFireAuth.currentUser.sendEmailVerification()
  //     .then(() => {
  //       this.router.navigate(['verify-email']);
  //     })
  // }

  async SendVerificationMail() {
    (await this.ngFireAuth.currentUser).sendEmailVerification().then(() => {
      console.log('email sent');
      this.router.navigate(['email-verification']);
    });
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email has been sent, please check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  LogOut() {
    this.ngFireAuth.signOut().then(() => {
      localStorage.clear();
      this.userData = null;
      this.userInfo = null;
      this.router.navigate(["login"]);
    })
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.userData = user;
      this.setUserData(user.uid)
      // this.getUserInfo(user);
    }
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user.emailVerified !== false) ? true : false;
  }

  // Sign in with Gmail
  GoogleAuth() {
    // return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        this.userData = result.user;
        if (result.operationType != 'signIn') {
          this.createUserFromGoogle(result);
        } else {
          this.getUserInfo(this.userData);
        }
        this.router.navigate(["practiceManager"])
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    // this.authService.GoogleAuth();
  }

  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth.signInWithPopup(provider)
      .then((result) => {
        if (result.operationType != 'signIn') {
          this.createUserFromGoogle(result);
        }
        this.ngZone.run(() => {
          this.router.navigate(['user-profile']);
        })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      isAdmin: false
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  async setUserData(uid, navigateToLogin = false) {
    const q = query(collection(this.firestore, 'users'), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      let user = doc.data() as User;

      if (navigateToLogin) {
        if (user.isAdmin) {
          this.userInfo = user;
          this.router.navigate(['practiceManager']);
        } else {
          this.util.presentErrorToast("Utente non autorizzato all'accesso")
        }
      }
    });

  }


  // Sign-out 
  SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['welcome']);
    })
  }

  createUser(data?) {
    console.log("createUser")
    console.log(data)
    let user: User = {
      // $key: "GtQHObEzl4QtbqRkEJr8mgRM8d83",
      // uid: "GtQHObEzl4QtbqRkEJr8mgRM8d83",
      // displayName: "Giuseppe Auriemma",
      // email: "g.auriemmma1994@gmail.com",
      // emailVerified: true,
      // photoURL: "photo",
      // name: "Giuseppe",
      // surname: "Auriemma",
      // isAdmin: false
      // $key: data.additionalUserInfo.profile.id,
      uid: data.user.uid,
      displayName: data.user.email,
      email: data.user.email,
      emailVerified: data.user.emailVerified,
      photoURL: "",
      name: data.user.email,
      surname: "",
      isAdmin: false
    }
    this.fireDB.create('users', user).then(res => {
      console.log("user saved on realtime db")
    });
  }

  createUserFromGoogle(data) {
    console.log(data)
    if (!data.additionalUserInfo.isNewUser) {
      let user: User = {
        // $key: data.additionalUserInfo.profile.id,
        uid: data.additionalUserInfo.profile.id,
        displayName: data.additionalUserInfo.profile.name,
        email: data.additionalUserInfo.email,
        emailVerified: data.additionalUserInfo.profile.verified_email,
        photoURL: data.additionalUserInfo.profile.picture,
        name: data.additionalUserInfo.profile.given_name,
        surname: data.additionalUserInfo.profile.family_name,
        isAdmin: false
      }

      // this.fireDB.create('answer', question).then(res => {
      //   console.log(res)
      // });
      this.fireDB.create('users', user).then(res => {
        console.log("user saved on realtime db")
      });
    }
  }

  async getUserByUID(uid) {
    // let user = this.fireDB.getList('users')
    // let list;

    // user.snapshotChanges().subscribe(res => {
    //   console.log(res)
    //   // this.answerList = [];
    //   list = [];
    //   res.forEach(item => {
    //     let a = item.payload.toJSON();
    //     a['$key'] = item.key;
    //     if (a["uid"] == uid) {
    //       this.userInfo = a as User;
    //     }
    //   });
    // });
    const q = query(collection(this.firestore, "users"), where("uid", "==", uid));
    // const q = query(collection(this.firestore, "endpoints"));

    const querySnapshot = await getDocs(q);
    return querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      return doc.data() as User;
      // this.endpoint = doc.data().ServerEndpoint;
    });
  }

  async getUserInfo(user) {
    const q = query(collection(this.firestore, "users"), where("uid", "==", user.uid));
    // const q = query(collection(this.firestore, "endpoints"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      this.userInfo = doc.data() as User;
      // this.endpoint = doc.data().ServerEndpoint;
    });
  }

}
