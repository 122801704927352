import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-message-modal',
  templateUrl: './confirm-message-modal.component.html',
  styleUrls: ['./confirm-message-modal.component.css']
})
export class ConfirmMessageModalComponent implements OnInit {

  constructor(public modalRef: NgbActiveModal) { }

  public title : string = "";
  public description : string = "";
  public hideUndo : boolean = true;

  ngOnInit(): void {
  }


  confirm(){
    this.modalRef.close(true)
  }

}
