import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from "environments/environment";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { FirebaseDbCollectionsService } from "./services/firebase-db-collections/firebase-db-collections.service";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireModule } from "@angular/fire/compat";
import { CanActivateRoute } from "./shared/canActivateRoute";
import { UtilService } from "./services/util/util.service";
import { PracticePageComponent } from './pages/practice-page/practice-page.component';
import { UserAnagraphicInfoComponent } from './shared/user-anagraphic-info/user-anagraphic-info.component';
import { ImgViewerModalComponent } from './shared/modals/img-viewer-modal/img-viewer-modal.component';
import { AffiliationPageComponent } from './pages/affiliation-page/affiliation-page.component';
import { CreateAffiliationModalComponent } from './shared/modals/create-affiliation-modal/create-affiliation-modal.component';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AllKeyFilterPipe } from './shared/pipes/all-key-filter.pipe';
import { NgxLoadingModule } from "ngx-loading";
import { ModuleConfigurationPageComponent } from './pages/module-configuration-page/module-configuration-page.component';
import { CreateAnswerModalComponent } from './shared/modals/create-answer-modal/create-answer-modal.component';
import { ConfirmMessageModalComponent } from './shared/modals/confirm-message-modal/confirm-message-modal.component';
import { AffiliationDetailModalComponent } from './shared/modals/affiliation-detail-modal/affiliation-detail-modal.component';
import { ServicesPageComponent } from "./pages/services-page/services-page.component";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginPageComponent,
    PracticePageComponent,
    UserAnagraphicInfoComponent,
    ImgViewerModalComponent,
    AffiliationPageComponent,
    CreateAffiliationModalComponent,
    AllKeyFilterPipe,
    ModuleConfigurationPageComponent,
    CreateAnswerModalComponent,
    ConfirmMessageModalComponent,
    AffiliationDetailModalComponent,
    ServicesPageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      relativeLinkResolution: 'legacy'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({})
  ],
  providers: [
    AuthenticationService,
    FirebaseDbCollectionsService,
    UtilService,
    AngularFirestoreModule,
    CanActivateRoute,
    NgbActiveModal
    //  Firebase
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
