import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Answer } from 'app/models/answer';
import { CompletePracticeStructure } from 'app/models/completePractice';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { ModulesManagementService } from 'app/services/modules/modules-management.service';
import { UtilService } from 'app/services/util/util.service';
import { collection, getDocs, Firestore, query, where } from '@angular/fire/firestore';
import { CreateAffiliationModalComponent } from 'app/shared/modals/create-affiliation-modal/create-affiliation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.css']
})
export class ServicesPageComponent implements OnInit {

  constructor(fb: FormBuilder, public moduleServ: ModulesManagementService, private modalServ: NgbModal, private fireDB: FirebaseDbCollectionsService, public util: UtilService, private firestore: Firestore) {
    this.form = fb.group({
      completeDescription: new FormControl("", Validators.required),
      type: new FormControl("", Validators.required),
      placeholder: new FormControl("", Validators.required),
      multiple: new FormControl(false),
    })
  }

  public form: FormGroup;
  public servicesList: Answer[] = [];
  public additionalActionList: CompletePracticeStructure[] = [];
  public disabled: Boolean;
  public state: string = "";
  public idFilter: string = "";
  public isEditing: boolean = false;
  public selectedService: Answer;
  public selectedServiceAction: CompletePracticeStructure = null;

  ngOnInit(): void {
    this.getServices();
    console.log()
  }

  resetForm() {
    this.form.controls.completeDescription.setValue("");
    this.form.controls.type.setValue("");
    this.form.controls.placeholder.setValue("");
    this.form.controls.multiple.setValue(false);
  }

  getServices() {
    this.servicesList = [];
    let list = this.moduleServ.answerList.filter(x => x.nextEvent.type == 'goToPay' && x.nextEvent.serviceName);
    for (let elem of list) {
      let exists = this.servicesList.find(x => x.nextEvent.serviceName == elem.nextEvent.serviceName);
      if (!exists) {
        this.servicesList.push(elem);
      }
    }
    console.log(this.servicesList)
  }

  onSelectService(service) {
    this.selectedService = service;
    this.resetForm();

    this.getAdditionalActions();
  }

  async getAdditionalActions() {
    this.additionalActionList = [];
    const q = query(collection(this.firestore, "additionalAction"), where("serviceName", "==", this.selectedService.nextEvent.serviceName));
    // const q = query(collection(this.firestore, "additionalAction"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      let action = doc.data() as CompletePracticeStructure;
      action.$key = doc.id;
      this.additionalActionList.push(action);
    });
  }

  createAdditionalAction() {
    if (this.form.valid) {

      let action: CompletePracticeStructure = {
        "serviceName": this.selectedService.nextEvent.serviceName,
        "description": this.form.value.completeDescription,
        "type": this.form.value.type,
        "placeholder": this.form.value.placeholder,
        "multiple": this.form.value.multiple,
        "files": [null],
        "values": [""],
        "indexs": [0],
        "questionID": ""
      };

      if (this.isEditing) {
        action.$key = this.selectedServiceAction.$key;
        this.fireDB.firestoreUpdate('additionalAction', action).then(res => {
          this.util.presentSuccessToast("Azione addizionale modificata correttamente");
          this.getAdditionalActions()
          this.resetForm()
          this.isEditing = false
        });
      } else {
        this.fireDB.firestoreCreate('additionalAction', action).then(res => {
          this.util.presentSuccessToast("Azione addizionale creata correttamente");
          this.resetForm();
          this.getAdditionalActions()
        });
      }

    } else {
      this.util.presentWarningToast("Compilare tutti i campi obbligatori")
    }
  }

  close() {
    this.resetForm()
    this.selectedService = null;
    this.additionalActionList = [];
    this.selectedServiceAction = null;
    this.isEditing = false;
  }

  delete(service) {
    this.fireDB.firestoreDelete('additionalAction', service.$key).then(res => {
      this.util.presentSuccessToast("Azione addizionale eliminata correttamente");
      this.getAdditionalActions()
    });
  }

  editServiceAction(service) {
    this.isEditing = true;
    this.selectedServiceAction = service;
    this.form.controls.completeDescription.setValue(service.description);
    this.form.controls.type.setValue(service.type);
    this.form.controls.placeholder.setValue(service.placeholder);
    this.form.controls.multiple.setValue(service.multiple);

    let form = window.document.getElementById("edit-form");
    form.scrollIntoView();
  }

  confirmDeleteServirce(service: CompletePracticeStructure) {
    const modalRef = this.modalServ.open(ConfirmMessageModalComponent,
      {
        size: 'md',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Elimina";
    modalRef.componentInstance.description = "Sei sicuro di voler procedere con l'eliminazione?";
    modalRef.componentInstance.hideUndo = false;

    modalRef.result.then((data) => {
      // on close
      if (data) {
        this.delete(service);
      }
    });
  }

}
