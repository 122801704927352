import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-img-viewer-modal',
  templateUrl: './img-viewer-modal.component.html',
  styleUrls: ['./img-viewer-modal.component.css']
})
export class ImgViewerModalComponent implements OnInit {

  constructor(public modalServ: NgbModal) { }

  public imgURL: string = "";

  ngOnInit(): void {
  }

}
