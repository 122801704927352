import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/models/user';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { UtilService } from 'app/services/util/util.service';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';
import { Elaborate } from 'app/models/elaborate';
import { LoadingService } from 'app/services/loading/loading.service';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


@Component({
  selector: 'app-user-anagraphic-info',
  templateUrl: './user-anagraphic-info.component.html',
  styleUrls: ['./user-anagraphic-info.component.css']
})
export class UserAnagraphicInfoComponent implements OnInit, AfterViewInit {


  public userForm: FormGroup;
  public user: User;
  public selectedTab: string = "anagraphic";

  
  fileInput: any;
  selectedFiles;
  title: string = "";

  constructor(fb: FormBuilder, public authServ: AuthenticationService, private loadingServ: LoadingService, private firestore: Firestore, private fireDB: FirebaseDbCollectionsService, public modalServ: NgbModal, public util: UtilService) {
    this.userForm = fb.group({
      name: new FormControl("", Validators.required),
      surname: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      cellPhone: new FormControl("", Validators.required),
      cf: new FormControl("", Validators.required),
      birthDate: new FormControl("", Validators.required),
      birthPlace: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      postalCode: new FormControl("", Validators.required),
      $key: new FormControl(""),
    })
  }


  ngOnInit() {
    this.getElaboratesList();
  }

  ngAfterViewInit() {
    this.userForm.controls.name.setValue(this.user.name ? this.user.name : "");
    this.userForm.controls.surname.setValue(this.user.surname ? this.user.surname : "");
    this.userForm.controls.email.setValue(this.user.email ? this.user.email : "");
    this.userForm.controls.cellPhone.setValue(this.user.cellPhone ? this.user.cellPhone : "");
    this.userForm.controls.cf.setValue(this.user.cf ? this.user.cf : "");
    this.userForm.controls.birthDate.setValue(this.user.birthDate ? this.user.birthDate : "");
    this.userForm.controls.birthPlace.setValue(this.user.birthPlace ? this.user.birthPlace : "");
    this.userForm.controls.address.setValue(this.user.address ? this.user.address : "");
    this.userForm.controls.city.setValue(this.user.city ? this.user.city : "");
    this.userForm.controls.postalCode.setValue(this.user.postalCode ? this.user.postalCode : "");
  }

  updateUserInfo() {
    this.fireDB.firestoreUpdate('users', this.userForm.value).then(res => {
      console.log("user saved on realtime db")
    });
  }

  openUserSign() {
    if (this.user.signatureURL) {

      // const modalRef = this.modalServ.open(ImgViewerModalComponent,
      //   {
      //     size: 'lg',
      //     backdrop: 'static'
      //     // windowClass: "custom-large-modal" 
      //   });
      // modalRef.componentInstance.imgURL = this.user.signatureURL;
      window.open(this.user.signatureURL);
    } else {
      this.util.presentWarningToast("Firma non presente");
    }
  }

  openUserDoc() {
    if (this.user.firstIdentityDoc || this.user.secondIdentityDoc) {
      // const modalRef = this.modalServ.open(ImgViewerModalComponent,
      //   {
      //     size: 'lg',
      //     backdrop: 'static'
      //     // windowClass: "custom-large-modal" 
      //   });
      // modalRef.componentInstance.imgURL = this.user.firstIdentityDoc;
      window.open(this.user.firstIdentityDoc);
      window.open(this.user.secondIdentityDoc);
    } else {
      this.util.presentWarningToast("Documento di Identità non presente");
    }

  }

  public elaboratesList: Elaborate[] = []

  async getElaboratesList() {
    this.elaboratesList = [];
    const q = query(collection(this.firestore, "elaborates"), where("userID", "==", this.user.uid));
    // const q = query(collection(this.firestore, "elaborates"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      let elaborate = doc.data() as Elaborate;
      elaborate.$key = doc.id;
      this.elaboratesList.push(elaborate);
    });
  }

  addElaborate(downloadURL) {
    let elaborate: Elaborate = {
      title: this.title,
      date: new Date().getTime(),
      fileURL: downloadURL,
      practiceID: "",
      userID: this.user.uid,
      state: ""
    }
    this.fireDB.firestoreCreate('elaborates', elaborate).then(res => {
      this.util.presentSuccessToast("Elaborato caricato con successo");
      this.getElaboratesList()
    });
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload() {
    this.loadingServ.loading = true;
    console.log("upload")
    const storage = getStorage();

    const randomId = this.user.$key + this.selectedFiles[0].name;
    const storageRef = ref(storage, randomId);
    const uploadTask = uploadBytesResumable(storageRef, this.selectedFiles[0]);

    uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        break;
    }
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      this.loadingServ.loading = false;
      this.addElaborate(downloadURL)
    });
  }
);

    // const randomId = this.authServ.userInfo.$key + new Date().getTime();
    // const ref = this.afStorage.ref(randomId);
    // const task = this.afStorage.upload(randomId, event.target.files[0]);

    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    // task.snapshotChanges().pipe( 
    //   finalize(() => {
    //     ref.getDownloadURL().subscribe(url => {

    //     });
    //   })
    // ).subscribe()
  }

  openFile(fileURL){
    window.open(fileURL)
  }

  changeTab(tab) {
    this.selectedTab = tab
  }
}
