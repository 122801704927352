import { Injectable } from '@angular/core';
import { Practices } from 'app/models/practices';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private toastr: ToastrService) { }


  public presentSuccessToast(message) {
    this.toastr.success(
      '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">' + message + '</span>',
      "",
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-success alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }

  public presentErrorToast(message) {
    this.toastr.error(
      '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">' + message + '</span>',
      "",
      {
        timeOut: 4000,
        enableHtml: true,
        closeButton: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }
  public presentWarningToast(message) {
    this.toastr.warning(
      '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">' + message + '</span>',
      "",
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-warning alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }

  public convertGetTimeToStringDate(getTime) {
    let date = new Date(getTime);

    return (date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate().toString()) + "/" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()) + "/" + date.getFullYear();
  }

  public getPracticeState(practice: Practices) {
    if (practice.open) {
      if (!practice.isPaid) {
        return "Da saldare";
      } else {
        if (practice.inProgress) {
          return "In Lavorazione"
        }
        return "Aperta"
      }
    } else if (practice.complete) {
      return "Chiusa"
    }
    return "";
  }
}
