import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allKeyFilter'
})
export class AllKeyFilterPipe implements PipeTransform {

  transform(array: any[], string: string) {
    let result = [];

    if (!string) {
      result = array;
    } else {
      result = array.filter(x => {
        for (let key in x) {
          if (x[key].toString().indexOf(string) > - 1) {
            return true;
          }
        }
        return false;
      })
    }

    return result;
  }

}
