import { Component, OnInit } from '@angular/core';
import { CompletePractice } from 'app/models/completePractice';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { UtilService } from 'app/services/util/util.service';
import { Firestore, query, where } from '@angular/fire/firestore';
import { collection, getDocs } from '@firebase/firestore';
import { User } from 'app/models/user';
import { Practices } from 'app/models/practices';
import { Router } from '@angular/router';
import { Affiliation } from 'app/models/affilitian';
import { CreateAffiliationModalComponent } from 'app/shared/modals/create-affiliation-modal/create-affiliation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AffiliationDetailModalComponent } from 'app/shared/modals/affiliation-detail-modal/affiliation-detail-modal.component';


@Component({
  selector: 'app-affiliation-page',
  templateUrl: './affiliation-page.component.html',
  styleUrls: ['./affiliation-page.component.css']
})
export class AffiliationPageComponent implements OnInit {

  constructor(private authServ: AuthenticationService, private modalServ: NgbModal, private router: Router, private fireDB: FirebaseDbCollectionsService, public util: UtilService, private firestore: Firestore) { }



  public affiliationList: Affiliation[] = [];
  public disabled: Boolean;
  public search: string = "";

  ngOnInit(): void {
    this.getAffiliations();
  }

  async getAffiliations() {
    this.affiliationList = [];
    // const q = query(collection(this.firestore, "practices"), where("userID", "==", this.authServ.userInfo.uid));
    const q = query(collection(this.firestore, "affiliations"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      let affiliation = doc.data() as Affiliation;
      affiliation.$key = doc.id;
      this.affiliationList.push(affiliation);
    });

  }

  openPractice(practice) {
    // this.router.navigate(["practice/" + practice.$key]);
  }

  openNewAffiliation() {
    const modalRef = this.modalServ.open(CreateAffiliationModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });

    modalRef.result.then((data) => {
      // on close
      this.getAffiliations();
    }, (reason) => {
      // on dismiss
      this.getAffiliations();
    });

  }

  editAffiliation(affiliation: Affiliation) {
    const modalRef = this.modalServ.open(CreateAffiliationModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.affiliation = affiliation;
    modalRef.componentInstance.title = "Modifica Affiliazione";

    modalRef.result.then((data) => {
      // on close
      this.getAffiliations();
    }, (reason) => {
      // on dismiss
      this.getAffiliations();
    });
  }

  openAffiliationDetail(affiliation: Affiliation) {
    const modalRef = this.modalServ.open(AffiliationDetailModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.affiliation = affiliation;
    modalRef.componentInstance.title = "Dettagli Affiliazione";

    modalRef.result.then((data) => {
      // on close
      this.getAffiliations();
    }, (reason) => {
      // on dismiss
      this.getAffiliations();
    });
  }

}
