import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Affiliation } from 'app/models/affilitian';
import { Practices } from 'app/models/practices';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UtilService } from 'app/services/util/util.service';
import { collection, getDocs } from '@firebase/firestore';
import { Firestore, query, where } from '@angular/fire/firestore';
import { ModulesManagementService } from 'app/services/modules/modules-management.service';
import { Module } from 'app/models/module';

@Component({
  selector: 'app-affiliation-detail-modal',
  templateUrl: './affiliation-detail-modal.component.html',
  styleUrls: ['./affiliation-detail-modal.component.css']
})
export class AffiliationDetailModalComponent implements OnInit {

  public practicesList: Practices[] = [];
  public affiliation: Affiliation;
  public title: string = "";

  constructor(public authServ: AuthenticationService, public modalRef: NgbActiveModal, public moduleServ: ModulesManagementService, public util: UtilService, private firestore: Firestore) {
  }

  ngOnInit(): void {
    if (this.affiliation) {
      this.getAffiliationPractices();
    }
  }

  async getAffiliationPractices() {
    this.practicesList = [];
    const q = query(collection(this.firestore, "practices"), where("affiliationID", "==", this.affiliation.$key));
    // const q = query(collection(this.firestore, "practices"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let practice = doc.data() as Practices;
      practice.$key = doc.id;
      this.practicesList.push(practice);
    });

  }

  getTotal() {
    let total = 0;
    for (let practice of this.practicesList) {
      total = + practice.price ? parseFloat(practice.price) : 0;
    }

    return total;
  }

  getModuleTotal(module: Module) {
    let total = 0;
    let practicesList = this.getModulePractices(module);
    for (let practice of practicesList) {
      total = + practice.price ? parseFloat(practice.price) : 0;
    }
    return total;
  }

  getModulePractices(module: Module) {
    return this.practicesList.filter(x => {
      if (x.parsedModule) {
        return x.parsedModule.$key == module.$key;
      }
      return false;
    });
  }

  getModuleLength(module: Module) {
    let practicesList = this.getModulePractices(module);
    return practicesList.length;
  }

}
