import { Component, OnInit } from '@angular/core';
import { CompletePractice } from 'app/models/completePractice';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FirebaseDbCollectionsService } from 'app/services/firebase-db-collections/firebase-db-collections.service';
import { UtilService } from 'app/services/util/util.service';
import { Firestore, query, where } from '@angular/fire/firestore';
import { collection, getDocs } from '@firebase/firestore';
import { User } from 'app/models/user';
import { Practices } from 'app/models/practices';
import { Router } from '@angular/router';
import { Module } from 'app/models/module';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModulesManagementService } from 'app/services/modules/modules-management.service';
import { Question } from 'app/models/question';
import { CreateAnswerModalComponent } from 'app/shared/modals/create-answer-modal/create-answer-modal.component';
import { Answer } from 'app/models/answer';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';


@Component({
  selector: 'app-module-configuration-page',
  templateUrl: './module-configuration-page.component.html',
  styleUrls: ['./module-configuration-page.component.css']
})
export class ModuleConfigurationPageComponent implements OnInit {

  constructor(private authServ: AuthenticationService, public moduleServ: ModulesManagementService, private modalServ: NgbModal, private router: Router, private fireDB: FirebaseDbCollectionsService, public util: UtilService, private firestore: Firestore) { }


  public selectedModule: Module;
  public disabled: Boolean;
  public search: string = "";


  public moduleQuestionsList: Question[] = [];

  ngOnInit(): void {

  }

  openPractice(practice) {
    // this.router.navigate(["practice/" + practice.$key]);
  }

  onModuleSelect(module) {
    this.selectedModule = module;
    this.moduleQuestionsList = this.moduleServ.questionList.filter(x => x.moduleID == this.selectedModule.$key);


  }


  openNewModule() {
    // const modalRef = this.modalServ.open(CreateModuleModalComponent,
    //   {
    //     size: 'lg',
    //     backdrop: 'static'
    //     // windowClass: "custom-large-modal" 
    //   });

    // modalRef.result.then((data) => {
    //   // on close
    //   this.getModules();
    // }, (reason) => {
    //   // on dismiss
    //   this.getModules();
    // });

  }

  editModule(Module) {
    // const modalRef = this.modalServ.open(CreateModuleModalComponent,
    //   {
    //     size: 'lg',
    //     backdrop: 'static'
    //     // windowClass: "custom-large-modal" 
    //   });
    // modalRef.componentInstance.Module = Module;
    // modalRef.componentInstance.title = "Modifica Affiliazione";

    // modalRef.result.then((data) => {
    //   // on close
    //   this.getModules();
    // }, (reason) => {
    //   // on dismiss
    //   this.getModules();
    // });
  }

  addNewQuestion() {
    const modalRef = this.modalServ.open(CreateAnswerModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Crea nuova domanda";
    modalRef.componentInstance.formType = "question";

    modalRef.result.then((data) => {
      let question: Question = {
        description: data.description,
        moduleID: this.selectedModule.$key
      }

      this.moduleServ.setQuestion(question).then(res => {
        this.onModuleSelect(this.selectedModule);
      });
    }, (reason) => {
      // on dismiss
      // this.getAffiliations();
    });

  }

  editQuestion(question: Question) {
    const modalRef = this.modalServ.open(CreateAnswerModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Modifica domanda";
    modalRef.componentInstance.inputData = question;
    modalRef.componentInstance.formType = "question";

    modalRef.result.then((data) => {
      question.description = data.description;

      this.moduleServ.setQuestion(question);
    }, (reason) => {
      // on dismiss
      // this.getAffiliations();
    });

  }

  getQuestionAnswer(question: Question) {
    return this.moduleServ.answerList.filter(x => x.questionID == question.$key)
  }


  editAnswer(answer: Answer) {
    const modalRef = this.modalServ.open(CreateAnswerModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Modifica risposta";
    modalRef.componentInstance.module = this.selectedModule;
    modalRef.componentInstance.inputData = answer;
    modalRef.componentInstance.formType = "answer";

    modalRef.result.then((data) => {
      answer.description = data.description;
      answer.nextEvent.type = data.nextEventType;
      answer.nextEvent.questionID = data.nextEventValue;
      answer.nextEvent.price = data.price ? data.price : 0;
      answer.nextEvent.serviceName = data.serviceName ? data.serviceName : "";
      answer.nextEvent.contactText = data.contactText ? data.contactText : "";

      if (data.enableAction) {
        answer.completeAction = {
          "description": data.completeDescription,
          "type": data.type,
          "placeholder": data.placeholder,
          "multiple": data.multiple,
          "files": [null],
          "values": [""],
          "indexs": [0],
          "questionID": ""
        };
      } else {
        answer.completeAction = null;
      }

      this.moduleServ.setAnswer(answer);
      // on close
      // this.getAffiliations();
    }, (reason) => {
      // on dismiss
      // this.getAffiliations();
    });

  }

  addNewAnswer(question: Question) {
    const modalRef = this.modalServ.open(CreateAnswerModalComponent,
      {
        size: 'lg',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    // modalRef.componentInstance.setQuestion(question);
    modalRef.componentInstance.title = "Crea nuova risposta";
    modalRef.componentInstance.module = this.selectedModule;
    modalRef.componentInstance.formType = "answer";

    modalRef.result.then((data) => {
      let key = this.moduleServ.questionList.find(x => x.description == question.description).$key;

      let answer: Answer = {
        description: data.description,
        nextEvent: {
          type: data.nextEventType,
          questionID: data.nextEventValue,
          price: data.price ? data.price : 0,
          serviceName: data.serviceName ? data.serviceName : "",
          contactText: data.contactText ? data.contactText : ""
        },
        questionID: key
      }

      if (data.enableAction) {
        answer.completeAction = {
          "description": data.completeDescription,
          "type": data.type,
          "placeholder": data.placeholder,
          "multiple": data.multiple,
          "files": [null],
          "values": [""],
          "indexs": [0],
          "questionID": ""
        };
      } else {
        answer.completeAction = null;
      }

      this.moduleServ.setAnswer(answer);
    }, (reason) => {
      // on dismiss
      // this.getAffiliations();
    });

  }

  deleteQuestion(question: Question) {
    if (question) {
      let questionAnwsers = this.moduleServ.answerList.filter(x => x.questionID == question.$key);

      for (let answer of questionAnwsers) {
        this.deleteAnswer(answer);
      }

      this.moduleServ.fireDB.deleteObject("questions", question.$key).then(res => {
        this.onModuleSelect(this.selectedModule);
      })
    }
  }

  deleteAnswer(answer: Answer) {
    if (answer) {
      this.moduleServ.fireDB.deleteObject("answer", answer.$key)
    }
  }

  confirmDeleteQuestion(question: Question) {
    const modalRef = this.modalServ.open(ConfirmMessageModalComponent,
      {
        size: 'md',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Elimina Domanda";
    modalRef.componentInstance.description = "Sei sicuro di voler procedere con l'eliminazione?";
    modalRef.componentInstance.hideUndo = false;

    modalRef.result.then((data) => {
      // on close
      if (data) {
        this.deleteQuestion(question);
      }
    });
  }

  confirmDeleteAnswer(answer: Answer) {
    const modalRef = this.modalServ.open(ConfirmMessageModalComponent,
      {
        size: 'md',
        backdrop: 'static'
        // windowClass: "custom-large-modal" 
      });
    modalRef.componentInstance.title = "Elimina Risposta";
    modalRef.componentInstance.description = "Sei sicuro di voler procedere con l'eliminazione?";
    modalRef.componentInstance.hideUndo = false;

    modalRef.result.then((data) => {
      // on close
      if (data) {
        this.deleteAnswer(answer);
      }
    });
  }

}
