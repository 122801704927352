// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyADxIyKr04454PjHj4_3p0eZ3C-d-r5gqg",
    authDomain: "docsiapp-327915.firebaseapp.com",
    databaseURL: "https://docsiapp-327915-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "docsiapp-327915",
    storageBucket: "docsiapp-327915.appspot.com",
    messagingSenderId: "840282895437",
    appId: "1:840282895437:web:9db4ed7b658b85f34ad804",
    measurementId: "G-8WQ0L712Z0"
  }
};
